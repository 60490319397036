@import url('https://fonts.googleapis.com/css2?family=Inter:wght@800;900&family=Titillium+Web:wght@200;400;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@800;900&family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Titillium+Web:wght@200;400;600;700&display=swap');

body {
  font-family: 'Titillium Web', sans-serif;
  overflow-x: hidden !important;

}

body,
html {
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}

p {
font-family: 'Titillium Web', sans-serif;
}

h1
h2
h3
h4
h5
h6 {
    font-family: 'Titillium Web', sans-serif;
}

.App {
  background-color: #000000;
  background-image: url('./img/page.svg');
  background-repeat: no-repeat;
  background-attachment: fixed;
background-size: cover;
max-width: 100vw;
overflow-x: hidden !important;
}

@tailwind base;
@tailwind components;
@tailwind utilities;