.lets-chat {
	padding-top: 80px;
	padding-bottom: 80px;

}

.text-content {
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
	margin-top: 25%;
}

.text-content h3 {
font-family: 'Titillium Web', sans-serif;
font-style: normal;
font-weight: 700;
font-size: 48px;
line-height: 72px;
color: #fff;
max-width:  534px;
padding-top: 10px;
padding-bottom: 10px;
}

.text-content p {
font-family: 'Titillium Web', sans-serif;
font-style: normal;
font-weight: 400;
font-size: 28px;
line-height: 40px;
color: rgba(255, 255, 255, 0.7);
}

.cards-content {
display: grid;
grid-template-columns: 1fr 1fr;
gap: 25px;
}

.cards-content a{
	
text-decoration: none;
	}

.social-card {
padding: 48px 24px;
	background: rgba(15, 15, 15, 0.5);
border: 1px solid rgba(255, 255, 255, 0.16);
}

.social-card img {
	height: 87px;
	width: 80px;
}

.social-card h3 {
font-family: 'Titillium Web', sans-serif;
font-style: normal;
font-weight: 400;
font-size: 28px;
line-height: 40px;
color: #FFFFFF;
padding-top: 30px;
padding-bottom: 10px;
text-decoration: none;
}

.social-card a {

text-decoration: none;
}

@media (max-width: 810px) {
.lets-chat {
	padding-top: 0px;
	padding-bottom: 40px;
		margin: 8px;
}

.text-content h3 {
font-size: 44px;
line-height: 52px;

}

.text-content p {
font-size: 20px;
}

.social-card {
padding: 25px 20px;
	background: rgba(15, 15, 15, 0.5);
border: 1px solid rgba(255, 255, 255, 0.16);
}

.social-card img {
	height: 70px;
	width: 70px;
}

.social-card h3 {
font-weight: 400;
font-size: 22px;
line-height: 40px;
color: #FFFFFF;
padding-top: 15px;
}

.cards-content {
margin-top: 15px;
gap: 15px;
}
}