@-webkit-keyframes action {
    0% { transform: translateY(0); }
    100% { transform: translateY(-20px); }
}

@keyframes action {
    0% { transform: translateY(0); }
    100% { transform: translateY(-20px); }
}

.round-button:hover {
	-webkit-animation: 0;
    animation: 0;
}

.round-button {
	background: transparent;
	border: 2px solid #ffffff;
	height: 86px;
	width: 86px;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	-webkit-animation: action .5s infinite  alternate;
    animation: action .5s infinite  alternate;
}

@media (max-width: 810px) {
.round-button {
	height: 66px;
	width: 66px;
}

.round-button:hover {
	-webkit-animation: 0;
    animation: 0;
}
}