.video-frame {
  height: 450.72px;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.24), rgba(0, 0, 0, 0.24)),
    #ffc817;
  box-shadow: 0px 13.2184px 26.4368px #0a0909;

}

.title {
  font-family: "Titillium Web", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 1.5rem;
}

.brand-img {
  border: 1px solid rgba(255, 255, 255, 0.17);
}

.stat {
  border-top: 1px solid rgba(255, 255, 255, 0.17);
  border-bottom: 1px solid rgba(255, 255, 255, 0.17);
}

.duration h3 {
  font-family: "Raleway";
  font-size: 1rem;
  color: rgba(255, 255, 255, 0.8);
}

.duration h2 {
  font-family: "Titillium Web", sans-serif;
  font-style: normal;
  font-size: 1rem;

  color: rgba(255, 255, 255, 0.8);
}

.pool h3 {
  font-family: "Raleway";
  font-size: 1rem;
  color: rgba(255, 255, 255, 0.8);
}

.pool h2 {
  font-family: "Titillium Web", sans-serif;
  font-style: normal;
  font-size: 1.5rem;
  color: #ffc817;
}

.brand-name {
  font-family: "Raleway", sans-serif;
  color: #ffffff;
}

.sec {
  background: rgba(2, 2, 2, 0.5);
}

.btn-connect {
  border: 1px solid #ffc817;
}

@media (max-width: 810px) {
  .video-frame {
 height: 220.72px;
  }

.mobile-title {
    display: block;
    flex-direction: column;
    align-items: center;
}
.player-wrapper {
  position: relative;
  padding-top: 56.25%; /* 720 / 1280 = 0.5625 */
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}

}

