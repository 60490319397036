.how-it-works {
	padding-top: 80px;
	padding-bottom: 80px;
}
.works-row-section {
	display: flex;
flex-direction: column;
justify-content: center;
gap: 28px;
align-items: flex-start;
margin-top: 40px;
margin-bottom: 50px;
}

.works-card {
	display: flex;
	flex-direction: column;
	align-items: flex;
	justify-content: flex;
padding: 30px 24px;
background-color: rgba(15, 15, 15, 0.5);
border: 1px solid rgba(255, 255, 255, 0.17);
border-radius: 4px;
}

.works-card h3 {
font-size: 24px;
font-family: 'Titillium Web', sans-serif;
font-style: normal;
font-weight: 600;
line-height: 36px;
color: #FFC817;
padding-bottom: 10px;

}

.works-card p {
font-family: 'Raleway', sans-serif;
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 28px;color: rgba(255, 255, 255, 0.7);
/*width: 334px;*/
}

@media (max-width: 810px) {
	.how-it-works {
	margin: 10px;
}

.works-row-section {

	display: flex;
align-items: center;
margin-top: 10px;
margin-bottom: 28px;
gap: 28px;
justify-content: flex;

}

.works-card {
	align-items: flex-start;
	justify-content: flex-start;
padding: 25px 24px;
background-color: rgba(15, 15, 15, 0.5);
border: 1px solid rgba(255, 255, 255, 0.17);
border-radius: 4px;
margin: auto;
}

}