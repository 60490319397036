.partners {
	padding-top: 50px;
	padding-bottom: 80px;
	width: 100%;
}

.partner-p {
	padding-bottom: 60px;
}

.partner-title {
align-items: center;
justify-content: center;
display: flex;
}

.partner-title h2{
		font-family: 'Titillium Web', sans-serif;
		font-size: 48px;
		font-weight: 600;
		text-transform: capitalize;
		max-width: 870px;
		text-align: center;
		line-height: 72px;
}

.partners-logos {
	border: 1px solid #554512;
border-radius: 55px;
display: flex;
flex-direction: row;
justify-content: space-evenly;
align-items: center;
padding: 32px 0px;
gap: 8px;
align-self: stretch;
flex-grow: 0;
width: 100%;


}

@media (max-width: 810px) {
.partner-title h2{
	font-size: 40px;
	line-height: 52px;
}

.partners-logos {
margin: auto;
display: flex;
flex-direction: column;
justify-content: space-between;
align-items: center;
gap: 48px;
padding: 32px 0px;
	border: none;
}
}