.video-banner {
margin-top: 180px;
padding-bottom: 80px;
}

.side-text-section {
position: relative;
top: 200px;
width: 100%;
display: inline-block;

}

.side-text-section h3 {
font-size: 64px;
font-family: 'Titillium Web', sans-serif;
letter-spacing: 0.02em;
color: #313131;
-webkit-text-stroke: 1px #686868;
text-transform: uppercase;
line-height: 24px;
font-weight: 700;
transform-origin: 0 0;
transform: rotate(-90deg);
position: absolute;
bottom: -550px;
}

.video-section {
padding-left: 80px;
border-radius: 4px;
}

.video-frame {
	height: 546px;
	width: 100%;
}
.video-bottom-section {
padding-top: 40px;
padding-bottom: 40px;
display: flex;
justify-content: space-between;
align-items: center;
}

.video-bottom-section h2 {
font-family: 'Titillium Web', sans-serif;
font-style: normal;
font-weight: 600;
font-size: 48px;
line-height: 76px;
text-align: right;
text-transform: capitalize;
width: 578px;
color: #ffffff;
}

@media (max-width: 810px) {
.side-text-section {
position: relative;
top: -40px;
width: 100%;
display: inline-block;
}


.side-text-section h3 {
font-size: 44px;
transform-origin: 0 0;
transform: rotate(0deg);
bottom: 0px;
text-align: center;
line-height: 52px;
}

.video-section {
padding-left: 0px;
border-radius: 4px;
}

.video-frame {
	height: 300px;
	width: 100%;
}

.video-bottom-section {
padding-top: 40px;
padding-bottom: 40px;
display: flex;
flex-direction: column-reverse;
justify-content: center;
align-items: center;
}

.video-bottom-section h2 {
	text-align: center;
	font-size: 30px;
line-height: 50px;
max-width: 306px;
padding-bottom: 30px;
}
}