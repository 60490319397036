.headText {
	  font-family: "Titillium Web", sans-serif;
}

.bodyText {
	  font-family: "Raleway", sans-serif;
}

.tabBottom {
border-bottom: 1px solid rgba(255, 255, 255, 0.17);
}

.tabActive{
	border-bottom: 1px solid #FFC817;
}

.creatorCard {
	background: rgba(2, 2, 2, 0.5);
border-radius: 8px;
}

.userAvatar {
	background: #020201
}
.copydiv {
	background: #020201;
	border: 1px solid #FFC817;
}
.voteCard {

}