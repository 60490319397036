:root {
--large: 15.625rem;
--largex: 10.563rem;
--small: 10px;
}

.hero {
padding-bottom: 6.25rem;
padding-top: 8.25rem;
min-height: 100vh;
}

.hero-text-section {
display: flex;
flex-direction: column;
align-items: flex-start;
justify-content: flex-start;
padding-top: 2.625rem;
padding-bottom: 1.5rem;
}

.hero-text-section h2 {
font-family: 'Titillium Web', sans-serif;
color: #ffffff;
font-weight: 700;
font-size: 4rem;
line-height: 4.75rem;
}

.orbit {
	display: flex;
align-items: flex-end;
justify-content: flex-end;
}

.hero-orbit-section {
height: 33.125rem;
width: 33.125rem;
border-radius: 50%;
display: flex;
align-items: center;
justify-content: center;
flex-direction: row;
border: 1.86867px dashed rgba(255, 200, 23, 0.17);
position: relative;
}

.inner-brand-logo {
height: 7.188rem;
width: 7.188rem;
border-radius: 50%;
border: 4px solid #ffc817;
display: flex;
align-items: center;
justify-content: center;
}

.inner-brand-logo img {
height: 7.188rem;
width: 7.188rem;
background-size: cover;
}

.animation {
background: #FFC817;
width: 6.563rem;
height: 6.563rem;
border-radius: calc( 15.625rem / 2);
display: inline-block;
animation: breath 6s ease-in-out infinite none running;
display: flex;
align-items: center;
justify-content: center;
flex-direction: row;

}

@keyframes breath {
25%, 50% {
background:  rgba(255, 215, 88, 0.49);
width: var(--large);
height: var(--large);
border-radius: calc( var(--large) / 2);
}   
}


.orbit-card {
padding: 0.938rem;
background: #1E1E1E;
border-radius: 2.125rem;
position: absolute;

}

.orbit-card img{
	height: 80px;
	width: 80px;
}

.child-1 {
top: -2.5rem;
left: 6.875rem;
}

.child-2 {
top: 2.5rem;
right: 5px;
}

.child-3 {
top: 19.375rem;
right: -2.188rem;
}

.child-4 {
top: 26.875rem;
right: 15rem;
}

.child-5 {
top: 13.125rem;
left: -4.375rem;
}

@-webkit-keyframes swinging{
0%{-webkit-transform: rotate(10deg);}
50%{-webkit-transform: rotate(-5deg)}
100%{-webkit-transform: rotate(10deg);}
}

@keyframes swinging{
0%{transform: rotate(10deg);}
50%{transform: rotate(-5deg)}
100%{transform: rotate(10deg);}
}

.swingimage{
-webkit-transform-origin: 50% 0;
transform-origin: 50% 0;
-webkit-animation: swinging 3.5s ease-in-out forwards infinite;
animation: swinging 3.5s ease-in-out forwards infinite;
}


@media (max-width: 810px) {
.hero {
padding-bottom: 4.25rem;
padding-top: 3.25rem;
	margin: auto;
}

.hero-text-section {
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
padding-top: 2.625rem;
padding-bottom: 1.5rem;
}

.hero-text-section h2 {
text-align: center;
font-weight: 700;
font-size: 3rem;
line-height: 3.75rem;

}

.orbit {
	display: flex;
align-items: center;
justify-content: center;
margin-top: 70px;
}

.hero-orbit-section {
height: 21.125rem;
width: 21.125rem;
}

.inner-brand-logo {
height: 5rem;
width: 5rem;

}

.inner-brand-logo img {
height: 5rem;
width: 5rem;
}
.orbit-card {
padding: 0.638rem;
}

.animation {
background: #FFC817;
width: 5.563rem;
height: 5.563rem;

}

.orbit-card img{
	height: 60px;
	width: 60px;
}

.child-3 {
top: 13.375rem;
right: -1.5rem;
}

.child-4 {
top: 17.885rem;
right: 9rem;
}

.child-5 {
top: 9.125rem;
left: -2.375rem;
}

@keyframes breath {
25%, 50% {
background:  rgba(255, 215, 88, 0.49);
width: var(--largex);
height: var(--largex);
border-radius: calc( var(--largex) / 2);
}   
}

}