.why-us {
	padding-top: 80px;
	padding-bottom: 80px;
}

.why-text-section {
	display: flex;
	align-items: flex-start;
	margin-bottom:30px;
}

.why-text-section h2 {
	font-family: 'Titillium Web', sans-serif;
font-style: normal;
font-weight: 600;
font-size: 48px;
line-height: 72px;
text-transform: capitalize;
color: #FFFFFF;
}

.why-container {
width: 100%;
padding-top: 15px;
padding-bottom: 15px;
display:flex;
flex-direction: row;
align-items: center;
}

.box-1 {
	width: 40%;
height: 100%;
background-color:transparent;
display: flex;
flex-direction: column;
}

.box-2 {
	width: 60%;
	height: 100%;
display: flex;
flex-direction: column;
align-items: flex-start;
padding: 24px;
gap: 24px;
background: rgba(7, 7, 7, 0.7);
border: 1px solid #554512;
}

.box-inner-1 {
	height: 50%;
	width: 100%;
align-self: stretch;
align-items: flex-start;
justify-content: flex-start;
}

.box-inner-2 {
	height: 50%;
	width: 100%;
background: rgba(2, 2, 2, 0.5);
border-radius: 5px;
padding: 32px 32px 32px 24px;
align-self: stretch;
}

.tab {
	display: flex;
flex-direction: row;
justify-content: space-between;
align-items: center;
padding: 32px;
gap: 8px;
border-bottom: 1px solid rgba(255, 255, 255, 0.17);
cursor: pointer;

}

.tab h3 {
	font-family: 'Titillium Web', sans-serif;
font-style: normal;
font-weight: 400;
font-size: 28px;
line-height: 40px;
color: rgba(255, 255, 255, 0.5);
}

.active {
	background: #1D1C1C;
	border-bottom: none;
}

.active h3 {
	color: #FFC817;
}

.box-inner-1 h3 {
	font-family: 'Titillium Web', sans-serif;
font-style: normal;
font-weight: 600;
font-size: 24px;
line-height: 36px;
color: #FFFFFF;
padding-bottom: 10px;
}

.box-inner-1 p {
font-family: 'Raleway', sans-serif;
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 28px;
color: rgba(255, 255, 255, 0.7);
}

.box-inner-2 h3 {
	font-family: 'Titillium Web', sans-serif;
font-style: normal;
font-weight: 600;
font-size: 24px;
line-height: 36px;
color: #FFFFFF;
padding-bottom: 10px;
}

.box-inner-2 p {
font-family: 'Raleway', sans-serif;
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 28px;
color: rgba(255, 255, 255, 0.7);
}

@media (max-width: 810px) {
	.why-text-section {
	display: flex;
	align-items: center;
	justify-content: center;
}
.why-text-section h2 {
font-size: 44px;
text-align: center;
line-height: 52px;

}
.why-container {
width: 100%;
padding-top: 15px;
padding-bottom: 15px;
display:flex;
flex-direction: column;
align-items: center;
}

.box-1 {
	width: 100%;
height: 100%;
background-color:transparent;
display: grid;
grid-template-columns: 1fr;
gap: 25px;
}

.box-2 {
	width: 100%;

}
}