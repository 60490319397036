.faq {
	padding-top: 80px;
	padding-bottom: 80px;
}

.faq-title {
align-items: center;
justify-content: center;
display: flex;
flex-direction: column;
}

.faq-title h2{
		font-family: 'Titillium Web', sans-serif;
		font-size: 48px;
		font-weight: 600;
		text-transform: capitalize;
		max-width: 870px;
		text-align: center;
}

.faq-title p {
	font-family: 'Titillium Web', sans-serif;
font-style: normal;
font-weight: 400;
font-size: 28px;
line-height: 40px;
color: rgba(255, 255, 255, 0.7);
padding-top: 10px;
padding-bottom: 10px;
}

.faq-card {
margin-top: 30px;
margin-bottom: 30px;
background: rgba(15, 15, 15, 0.5);
border: 1px solid rgba(255, 255, 255, 0.16);
border-radius: 24px;
padding: 96px;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
}

.accordion-item {
	padding-top: 20px;
	padding-bottom: 40px;
	width: 80%;
border-bottom: 1px solid rgba(245, 245, 245, 0.13);
}

.accordion-item:last-child {
border-bottom: none;
}

.accordion-title {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}
.accordion-title h3 {
	font-family: 'Titillium Web', sans-serif;
font-style: normal;
font-weight: 400;
font-size: 28px;
line-height: 40px;
color: #FFFFFF;
text-align: left;
padding-top:20px;
}

.accordionButton {
	height: 52px;
	width: 52px;
	background: transparent;
	border: 1px solid #fff;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 20px;
cursor: pointer;

}

.accordion-c {
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
}

.accordion-c h3 {
	cursor: pointer;
}

.accordion-content {
	padding-top: 10px;
	padding-bottom: 20px;
}

.accordion-content p{
	font-family: 'Raleway', sans-serif;
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 28px;
color: rgba(255, 255, 255, 0.7);
max-width: 529px;
}

@media (max-width: 810px) {
.faq-title h2{
		font-family: 'Titillium Web', sans-serif;
		font-size: 44px;
}

.faq-title p {
text-align: center;
font-size: 20px;
line-height: 36px;
max-width: 320px;
}

.faq {
	padding-top: 20px;
	padding-bottom: 40px;
	margin: 20px;
}

.accordion-item {
	padding-top: 10px;
	width: 93%;
}

.accordion-title h3 {
font-style: normal;
font-weight: 400;
font-size: 20px;
line-height: 35px;
max-width: 240px;

}

.accordionButton {
	height: 42px;
	width: 42px;
}

.accordion-content p{
font-size: 14px;
}

.faq-card {
padding: 36px;
}
}