.cta {
	padding-top: 40px;
	padding-bottom: 40px;
	background: rgba(2, 2, 2, 0.5);
}

.cta-text-section {
	align-items: flex-start;
	justify-content: flex-start;
}

.cta-text-section h2 {
font-family: 'Titillium Web', sans-serif;
font-style: normal;
font-weight: 700;
font-size: 48px;
line-height: 72px;
text-transform: capitalize;
color: #FFFFFF;
padding-top: 15px;
padding-bottom: 15px;
}

.cta-text-section p {
font-family: 'Raleway', sans-serif;
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 28px;
color: rgba(255, 255, 255, 0.7);
max-width: 452px;
}

.cta-button-section {
	display: flex;
	flex-direction: row;
	align-items: flex-end;
	justify-content: flex-end;
	width: '60%';
text-align: right;
margin-top: 80px;
}

@media (max-width: 810px) {

.cta-text-section {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.cta-text-section h2 {
text-align: center;
font-size: 44px;
line-height: 52px;
}

.cta-text-section p {
text-align: center;
max-width: 365px;
}

.cta-button-section {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	width: '60%';
text-align: right;
margin-top: 30px;
margin-bottom: 30px;
}
}