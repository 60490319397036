.footer {
	padding-top: 50px;
}

.footer-about{
align-items: flex-start;
justify-content: flex-start;
	padding-top: 80px;
	padding-bottom: 40px;
	border-right: 1px solid rgba(255, 255, 255, 0.16);
}

.footer-about img {
	height: 83px;
	padding-bottom: 20px;
}

.footer-about p {
font-family: 'Raleway', sans-serif;
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 28px;
color: rgba(255, 255, 255, 0.7);
width: 303px;
}

.footer-about h3 {
padding-top: 46px;
width: 345px;
font-family: 'Raleway', sans-serif;
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 28px;
color: #FFFFFF;
}

.footer-menu {
align-items:	flex-start;
justify-content: flex-start;
padding-left: 40px;
	padding-top: 80px;
	padding-bottom: 40px;
}

.line {
border-right: 1px solid rgba(255, 255, 255, 0.16);
}

.line:last-child {
border-right: none;
}

.footer-menu h2 {
font-family: 'Titillium Web', sans-serif;
font-style: normal;
font-weight: 400;
font-size: 28px;
line-height: 40px;
color: #FFC817;
}

.footer-menu ul {
list-style: none;
display: flex;
flex-direction: column;
padding-top: 10px;
padding-left: 0;
}

.footer-menu ul li{
font-family: 'Raleway', sans-serif;
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 28px;
color: rgba(255, 255, 255, 0.7);
}

.footer-menu ul li a{
	font-family: 'Raleway', sans-serif;
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 28px;
	color: rgba(255, 255, 255, 0.7);
	text-decoration: none;
	cursor: pointer;
	}

@media (max-width: 810px) {
.footer-about {
		padding-top: 10px;
width: 100%;
		border-right: none;
		display: flex;
		align-items: center;
justify-content: center;
flex-direction: column;
}

.footer-about p {
text-align: center;
width: 345px;
}

.footer-about h3 {
padding-top: 20px;
width: 345px;
text-align: center;
}

.footer-menu {
	display: flex;
	flex-direction: column;
	align-items:	center;
justify-content: center;
width: 100%;
padding-left: 0px;
	padding-top: 5px;
	padding-bottom: 40px;
}

.foot {
	display: flex;
	flex-direction: column-reverse;
}
}