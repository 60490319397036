.rectangle-button {
	display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
padding: 16px 32px;
gap: 8px;
background: #FFC817;
font-family: 'Titillium Web', sans-serif;
font-style: normal;
font-weight: 600;
font-size: 24px;
line-height: 36px;
color: #000000;
text-decoration: none;
max-width: 500px;
}

@media (max-width: 768px) {
	.rectangle-button {
padding: 16px 24px;
gap: 8px;
font-size: 20px;
}
}