.search-box {
gap: 8px;
height: 88px;
background: rgba(2, 2, 2, 0.5);
border: 1px solid rgba(255, 255, 255, 0.16);
border-radius: 28px;
display: flex;
align-items: center;
justify-content: center;
padding-left: 24px;
margin-bottom: 40px;
}

.search-box input {
	padding: 10px;
	width: 100%;
	box-sizing: border-box;
	border: none;
	background: rgba(2, 2, 2, 0.5);
	color: #fff;
	font-family: 'Raleway', sans-serif;
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 28px;
}

.search-box input:focus {
	outline: none;
	background: rgba(2, 2, 2, 0.5);
}

.search-box input::placeholder {
	color: #fff;
	opacity: 0.5;
}

@media (max-width: 810px) {
	.search-box {
height: 68px;
margin-bottom: 20px;

border-radius: 24px;
	}

}