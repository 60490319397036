.preloaderHolder {
    height: 100vh;
    position: fixed;
    width: 100%;
    z-index: 999999;
    display: flex;
}

.left,
.right {
    width: 50%;
    transition: all 0.5s ease-in;
    background-color:  #000000;
    background-size: cover;
}

.close{
    visibility: hidden;
}

.close .left {
    transform: translate(-100%);
    opacity: 0;
}

.close .right {
    transform: translate(100%);
    opacity: 0;
}

.fadeOut{
    opacity: 0;
    transition: all .2s ease-in;
}

.content {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 35%;
    transform: translateY(-50%);
    transform: translateX(-50%);
    left: 50%;
}

.content .imgHolder {
    width: 200px;
    justify-content: center;
    align-items: center;
}

.loader {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    margin: 0 auto;
    margin-top: 20px;
    border: 2px solid #FFC817;
    border-bottom-color: transparent;
    animation-name: spin;
    animation-timing-function: linear;
    animation-duration: 1.0s;
    animation-iteration-count: infinite;
    display:flex;
    align-items: center;
    justify-content: center;
    
}

.logoBox {
    height: 100px;
    width: 100px;
 border-radius: 50%;
border: 4px solid #ffc817;
display: flex;
align-items: center;
justify-content: center;
}

.logoBox img {
height: 100px;
width: 100px;
background-size: cover;
}


@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}