.why-work {
	padding-top: 100px;
	padding-bottom: 100px;
}

.work-title {
		font-family: 'Titillium Web', sans-serif;
		font-size: 48px;
		font-weight: 600;
		text-transform: capitalize;
	
}

.workCard {
	background: rgba(2, 2, 2, 0.5);
border: 1px solid rgba(255, 255, 255, 0.16);
padding: 30px;
min-height: 490px;
}

.workCard h3{
font-size: 28px;
color: #fff;
font-weight: 400;
font-family: 'Titillium Web', sans-serif;
padding-top:10px;
padding-bottom:10px;
line-height: 40px;
}

.workCard p{
font-size: 16px;
color: #fff;
opacity: 0.6;
font-weight: 400;
font-family: 'Raleway', sans-serif;
padding-top:10px;
padding-bottom:10px;
line-height: 28px;
}

.workCard img{
height: 87px;
width: 80px;
padding-bottom: 10px;
}

@media (max-width: 810px) {
.work-title {
font-size: 44px;
}

.workCard {
padding: 25px;
min-height: 490px;
margin: 10px;
}

}